import { Line /*, mixins */ } from "vue-chartjs";
// const { reactiveProp } = mixins

export default {
  extends: Line,
  // mixins: [reactiveProp],
  data() {
    return {
      gradient: null
    };
  },
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  computed: {
    chartDataLocal() {
      function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      const getBackgroundColor = dataset => {
        if (["gradient", "smallGradient"].includes(dataset.backgroundColor) && dataset.borderColor && dataset.borderColor.startsWith("#") && dataset.borderColor.length === 7) {
          const {
            r,
            g,
            b
          } = hexToRgb(dataset.borderColor);
          const y1 = {
            gradient: 450,
            smallGradient: 100
          }[dataset.backgroundColor];
          const gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, y1);
          gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0)`);
          gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0.2)`);
          return gradient;
        }
        return dataset.backgroundColor;
      };
      return {
        ...this.chartData,
        datasets: this.chartData.datasets.map(dataset => {
          return {
            ...dataset,
            data: dataset.data.map(i => i < 0 ? null : i),
            backgroundColor: getBackgroundColor(dataset),
            pointBackgroundColor: "#6D8A9F",
            pointRadius: 1
          };
        })
      };
    }
  },
  mounted() {
    this.renderChart(this.chartDataLocal, this.options);
  }
};