//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoProjects from "./NoProjects.vue";
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    }
  },
  computed: {
    hasAccess() {
      return !this.$access.check("seo");
    },
    shouldShowModal() {
      return !(this.$store.state.user.user && !this.$store.state.seo.hasProjectsLoaded) && !(!this.$store.state.user.user || !this.$store.state.seo.hasProjects) && !this.hasAccess;
    }
  },
  watch: {
    shouldShowModal: {
      immediate: true,
      handler(v) {
        if (!v) {
          return;
        }
        this.$modal.open(() => import("@/components/modals/access/ModalSeoNoSubscription"));
      }
    }
  },
  components: {
    NoProjects
  }
};