//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalEditProject from "@/components/modals/ModalEditProject";
import ModalRemoveProject from "@/components/modals/ModalRemoveProject.vue";
import MainTitle from "@/components/MainTitle.vue";
import MonitoringTable from "@/components/Seo/MonitoringTable";
import ModalAddProject from "@/components/modals/ModalAddProject";
import SeoWrapper from "@/components/Seo/Wrapper";
export default {
  meta: {
    title: "Мониторинг позиций"
  },
  data() {
    return {
      refreshKey: 0,
      search: ""
    };
  },
  created() {
    this.$store.dispatch("seo/setHasProjects");
    this.$store.dispatch("seo/getQueriesStats");
  },
  methods: {
    onEdit({
      item,
      context
    }) {
      this.$refs.ModalEditProject.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    async onCopy({
      item
    }) {
      const new_project = {
        name: "Копия - " + item.name,
        datasource: item.datasource,
        status: 10
      };
      let queries = "";
      let products = "";
      let products2 = "";
      try {
        const queriesResponse = await this.$store.dispatch("seo/getProjectQueries", {
          id: item.id
        });
        const productsResponse = await this.$store.dispatch("seo/getProjectProducts", {
          id: item.id,
          own: 1
        });
        const products2Response = await this.$store.dispatch("seo/getProjectProducts", {
          id: item.id,
          own: 0
        });
        queriesResponse.data.forEach(item => {
          queries += item.query + "\n";
        });
        productsResponse.data.forEach(item => {
          products += item.sku + "\n";
        });
        products2Response.data.forEach(item => {
          products2 += item.sku + "\n";
        });
        const result = await this.$store.dispatch("seo/createProject", {
          name: new_project.name,
          datasource: new_project.datasource,
          status: new_project.status
        });
        const projectId = result === null || result === void 0 ? void 0 : result.data[0].id;
        const queriesArray = queries.split("\n").filter(item => item !== "");
        const productsArray = products.split("\n").filter(item => item !== "");
        await this.$store.dispatch("seo/replaceProjectQueries", {
          id: projectId,
          queries: queriesArray
        });
        await this.$store.dispatch("seo/replaceProjectProducts", {
          id: projectId,
          products: productsArray,
          own: 1
        });
        if (products2) {
          const products2Array = products2.split("\n").filter(item => item !== "");
          await this.$store.dispatch("seo/replaceProjectProducts", {
            id: projectId,
            products: products2Array,
            own: 0
          });
        }
        this.refreshKey++;
      } catch (e) {
        console.error(e);
      }
    },
    onRemove({
      item,
      context
    }) {
      this.$refs.modalRemoveProject.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    onAdded() {
      this.refreshKey++;
      this.hasItems = true;
    },
    onCreateProject() {
      this.$access.hasOrThrow("seo");
      this.$refs.modalAddProject.open();
    }
  },
  computed: {
    tableKey() {
      return this.refreshKey;
    },
    hasProjects() {
      return this.$store.state.seo.hasProjects;
    },
    projects() {
      return this.$store.state.seo.projects;
    },
    reportSettings() {
      return {
        search: this.search
      };
    },
    availableQueries() {
      return this.$store.state.seo.queriesLimit - this.$store.state.seo.currentQueriesCount;
    }
  },
  components: {
    ModalAddProject,
    ModalEditProject,
    ModalRemoveProject,
    MainTitle,
    MonitoringTable,
    SeoWrapper
  }
};